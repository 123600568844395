import React from "react"
import { Link } from "gatsby"

export default function WorldCanBe() {
  return (
    <section id="about" className="flashmessage">
      <div className="container" data-aos="fade-up" id="what-is-sterilwize" >
        <div className="col-lg-12 mb-6" style={{ backgroundColor: "#08263b", display: "contents", height: "300px", border: "none", textAlign: "center", }}>
          <div className="card" data-aos="zoom-in" data-aos-delay="100" style={{ backgroundColor: "#08263b", color: "white", fontWeight: 400, display: "inline-table", height: "200px", }}>
            <div className="card-body">
              <h3 style={{ marginTop: "30px",}} >
                <b style={{ marginTop: "30px", marginBottom: "30px", textAlign: "center", }} >
                  THE WORLD CAN BE YOURS!
                </b>
              </h3>
              <div >
                <p style={{marginBottom: "30px", textAlign: "center", }}>Our goal at ASAPient is to provide effective solutions designed to boost your business, be it small or a large organization. Our consultants design solutions tailored specifically to the needs of your business in order to ensure you achieve your dreams of success. Check out our custom solutions and services.</p>

              </div>


            </div>

          </div>
        </div>


        <div className="row about-extra">
          <div className="col-lg-6 pt-3 pt-lg-0" data-aos="fade-left" >
            <img style={{ height: "350px", width: "350px", position: "relative", }} src="/assets/img/ourvalue.png" alt="" />
          </div>
          <div
            className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 newera"
            data-aos="fade-right"
          >

            <header className="section-heade">
              <h2>Our Values</h2>
            </header>
            <p>
              “At ASAPient since our birth in 1999, our decisions are driven by our values. Our values define our work ethics, culture, highest standards leading to a highly motivated self driven team.”
            </p>

          </div>
        </div>

      </div>
    </section>
  )
}
