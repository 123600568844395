import React from "react"
import { Link } from 'gatsby'

export default function Entrepreneurs() {
  return (

    <section id="clients" className="section-bg">

      <br></br>
      <br></br>
      <section id="about">
        <div className="container" data-aos="fade-up">

          <section id="our-products">
            <div className="row row-eq-height justify-content-center">
              <div className="col-lg-4 mb-4">
                <div className="card" data-aos="zoom-in" data-aos-delay="200">
                  <div className="card-body">
                    <img
                      src="/assets/img/download/Global_Consulting_Solutions.png"
                      className="product"
                    />
                  
                    <h5 className="card-title">
                      Global Consulting Solutions
                    </h5>
                    <br></br>
                  </div>
                  {/* <div id="datalogger-text">
                    <p style={{ fontSize: "16px", }}>
                      For businesses seeking expansion we will be a trusted partner with technical expertise, support, implementation capabilities, industry experience, and a global network.
                    </p>

                  </div> */}
                </div>
              </div>

              <div className="col-lg-4 mb-4">
                <div className="card" data-aos="zoom-in" data-aos-delay="300">
                  <div className="card-body">
                    <img
                      src="/assets/img/download/startuptech.jpeg"
                      className="product"
                    />
 <br></br>

                    <h5 className="card-title">
                      Technology Partnerships
                    </h5>
                    <br></br>
                  </div>


                </div>
              </div>

              <div className="col-lg-4 mb-4">
                <div className="card" data-aos="zoom-in" data-aos-delay="100">
                  <div className="card-body">

                    <img
                      src="/assets/img/download/venturecapital1.jpeg"
                      className="product"
                    />

<br></br>
                    <h5 className="card-title">
                      Venture Capital
                    </h5>
                    <br></br>
                  </div>

                </div>
              </div>
              {/* <div className="col-lg-3 mb-4">
                <div className="card" data-aos="zoom-in" data-aos-delay="100">
                  <div className="card-body">
                   
                      <img
                        src="/assets/img/download/venturecapital1.jpeg"
                        className="product"
                      />
                    
                   
                    <h5 className="card-title">
                    Venture Capital
                    </h5>
                  </div>
                  
                </div>
              </div> */}

            </div>
          </section>
        </div>
        <br></br>
<br></br>
      </section>


    </section>

  )
}
