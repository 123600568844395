import React from "react"
import "../styles/logo.css"
import "../components/Layout"
import Navigation from "../components/Navigation"
import Includes from "../components/Includes"
import Footer from "../components/Footer"
import Header from "../components/Header"

import Entrepreneurs from "../components/Entrepreneurs"


export default function entrepreneurs() {
  return (<>
            <Header/>
            <Navigation/>
            <br></br>
            <Entrepreneurs/>
            <Includes/>
            <Footer/>

        
  </>)
}